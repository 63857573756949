import axios from "axios";
import NetworkManager from "@/utils/NetworkManager";
import {ApiPath} from "@/tools/networking/network_tools";
import EventBus from "@/utils/EventBus";
import {HashPassword} from "@/tools/hash_tools";

export default class RequestManager {
    constructor(config, cache) {
        this.__cache = cache;
        this.network = new NetworkManager(config);
    }
    async getChunks() {
        const uri = this.network.uriPath("dbms", ApiPath.ChunksV1);
        const info = await this.post(uri);
        return this.__setChunks(info.data);
    }
    async createStripeIntents(body) {
        const uri = this.network.uriPath("dbms", ApiPath.StrIntents);
        return await this.post(uri, body);
    }
    async loginFromCache(){
        const login = this.__cache.getCache("login");
        if(!login) return false;
        const data = login.data;
        const token = login.token;
        if(!data || !token) {
            this.__cache.remove("login");
            return false;
        }
        const userData = await this.login(data, token);
        const loggedIn = !!userData;
        if(!loggedIn) this.__cache.remove("login");
        return loggedIn;
    }
    async login(data, password, isCred = false) {
        const params = {data: data};
        if(isCred) params.cred = password;
        else params.token = password;
        const uri = this.network.uriPath("dbms", ApiPath.Login);
        const response = await this.post(uri, params);
        return this.__getLoginUser(response);
    }
    async signup(email, userName, password) {
        const hash = await HashPassword(password)
        const params = {
            cred: hash,
            email: email,
            userName: userName,
        }
        const uri = this.network.uriPath("dbms", ApiPath.Signup);
        return await this.post(uri, params);
    }
    async resetPassword(email, password, verifyData) {
        const hash = await HashPassword(password)
        const params = {
            data: hash,
            email: email,
            cred: verifyData,
            type: "password",
        }
        const uri = this.network.uriPath("dbms", ApiPath.Verify);
        return await this.post(uri, params);
    }
    async forget(email) {
        const params = {
            data: email,
            type: "password"
        }
        const uri = this.network.uriPath("dbms", ApiPath.Forgot);
        return await this.post(uri, params);
    }
    async resendEmail(userData) {
        const params = {
            token: userData.token,
            type: "email",
        }
        const uri = this.network.uriPath("dbms", ApiPath.Resend);
        return await this.post(uri, params);
    }
    async verifyRequest(userData, verifyTo, verifyPin, newData=undefined) {
        const token = userData.token;
        const email = userData.user.email;
        const params = {
            email: email,
            token: token,
            type: verifyTo,
            cred: verifyPin.toString(),
            data: newData,
        }
        const uri = this.network.uriPath("dbms", ApiPath.Verify);
        return await this.post(uri, params);
    }
    async post(uri, data){
        const info = await axios.post(uri, data);
        if(!info) return {msg: "REQUEST_FAILED", data: undefined};
        return info.data;
    }
    async put(uri, contents){
        const headers = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            const request = await axios.put(uri, contents, headers);
            return (request.status === 200) ? "SUCCESS": "FAILED";
        }
        catch (e) {
            return String(e);
        }
    }
    __getLoginUser(response){
        const msg = response.msg;
        const userData = response.data;
        if(msg !== "SUCCESS" || !userData) return EventBus.emit("set-error", "Login failed!");
        EventBus.emit("user-logged-in", userData);
        return userData;
    }

    __setChunks(chunks) {
        if(!chunks || !chunks.length) return;
        const lst = chunks.pop();
        if(!lst) return;
        const advn = "Fj8mj4UG";
        const advs = "51JM3txHR";
        const elv = lst.endsWith("t") ? "test_": "live_";
        const st = "pk_";
        let fn = "";

        fn += st;
        fn += elv;
        fn += advs;
        fn += advn;

        let ind = 0;
        for (let i = 0; i < chunks.length; i++) {
            for (let j = 0; j < i+1 && i > 0; j++) ind += 1;
            if (ind >= chunks.length) break;
            fn += chunks[ind];
        }
        return fn;
    }
}
