<template>
  <div class="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex flex-col justify-center items-center">
    <div class="mb-4">
      <div class="font-semibold text-gray-900 dark:text-gray-200">Balance: {{ formatCurrency() }}</div>
    </div>
    <div>
      <button @click="topUp" class="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition duration-300 focus:outline-none">Top Up</button>
    </div>
  </div>
</template>


<script>
export default {
  name: "UserWallet",
  props: {
    userData: {type: Object, required: true},
  },
  methods: {
    getBalance() {
      return this.userData.account.balance;
    },
    formatCurrency() {
      const amount = this.getBalance();
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "EUR"
      }).format(amount);
    },
    topUp() {
      this.$router.push("/payment");
    }
  }
}
</script>
