<template>
  <div class="fixed top-0 left-0 w-full h-full flex justify-center items-center">
    <div class="absolute flex justify-center items-center inset-0 backdrop-filter backdrop-blur-md">
      <div class="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-md w-60 text-left">
        <p v-if="title" class="mb-4 text-xl font-semibold text-gray-900 dark:text-white text-center">{{ title }}</p>

        <form class="space-y-4 md:space-y-6" @submit.prevent="handleSubmit">
          <slot></slot>
          <div class="flex justify-center items-start">
            <button type="submit" class="w-28 text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{{ submitName }}</button>
            <button v-if="!ignoreClose" type="button" @click="$emit('close')" class="w-28 text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Close</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "OptionForm",
  emits: ['submit', 'close'],
  props: {
    title: {type: String, required: false},
    submitName: {type: String, required: true},
    ignoreClose: {type: Boolean, required: false},
  },
  methods: {
    handleSubmit() {
      this.$emit('submit');
    }
  }
}
</script>
