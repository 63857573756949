<template>
  <div>
    Payment is success
  </div>
</template>
<script>
export default {
  name: "PaymentSuccess"
}
</script>
