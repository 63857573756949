<template>
  <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
    <!-- Heading Columns -->
    <div class="flex mb-4">
      <div class="font-semibold text-gray-900 dark:text-gray-200 w-1/4">Name</div>
      <div class="font-semibold text-gray-900 dark:text-gray-200 w-1/3">Product Key</div>
      <div class="w-1/6"></div>
      <div class="w-1/6"></div>
    </div>

    <!-- Product List -->
    <div v-for="product in getProducts()" :key="product.id" class="mb-4">
      <div class="flex items-center mb-2">
        <input type="text" :value="product.prodName" @click="copyToClipboard(product.prodName)" readonly class="w-1/3 mr-4 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 px-3 py-2 rounded-md cursor-pointer">
<!--        <input type="text" :value="product.prodKey" @click="copyToClipboard(product.prodKey)" readonly class="w-3/5 mr-4 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 px-3 py-2 rounded-md cursor-pointer">-->
        <input type="text" :value="product.prodKey" @click="copyToClipboard(product.prodKey)" readonly class="w-4/5 mr-4 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 px-3 py-2 rounded-md cursor-pointer">
        <div class="w-2/6"></div>
        <div class="w-2/6"></div>
<!--        <div class="w-1/6">-->
<!--          <button @click="modifyProduct(product.id)" class="px-4 mr-2 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md">Modify</button>-->
<!--        </div>-->
<!--        <div class="w-1/6">-->
<!--          <button @click="deleteProduct(product.id)" class="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md">Delete</button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { IconsUI } from "@/utils/IconsUI";
import RequestManager from "@/utils/RequestManager";
import EventBus from "@/utils/EventBus";

export default {
  name: "ProductPage",
  props: {
    icons: { type: IconsUI, required: true },
    userData: { type: Object, required: true },
    request: { type: RequestManager, required: true },
  },
  data() {
    return {
      copiedMessageVisible: false,
    };
  },
  methods: {
    getProducts() {
      return this.userData.products || [];
    },
    modifyProduct(productId) {
      console.log(productId);
    },
    deleteProduct(productId) {
      console.log(productId);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      EventBus.emit("set-toast", "Copied!");
    }
  }
};
</script>
