import HomePage from "@/pages/HomePage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import ChangeInfo from "@/pages/ChangeInfo.vue";
import PaymentPage from "@/pages/PaymentPage.vue";
import PaymentSuccess from "@/pages/PaymentSuccess.vue";

export function Routes(cache, request, stripe) {
  return [
    { path: '/about', component: AboutPage},
    { path: '/change', component: ChangeInfo},
    { path: '/pay_success', component: PaymentSuccess},
    // { path: '/pricing', component: App, meta: { requiresAuth: true } },
    { path: '/', component: HomePage, props: { cache: cache, request: request }},
    {path: '/login', component: LoginPage, props: { cache: cache, request: request } },
    { path: '/payment', component: PaymentPage, props: { stripe: stripe, request: request }},
  ]
}
