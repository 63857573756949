<template>
  <div>
    <OptionForm title="Verify your email" submit-name="Verify" :ignore-close="true" @submit="verifyClicked">
      <OptionInput type="number" label="PIN" @onChange="setPin" />
      <OptionNav title="Haven't received yet?" @submit="resendClicked"  submit-name="Resend"/>
    </OptionForm>
  </div>
</template>
<script>
import RequestManager from "@/utils/RequestManager";
import OptionNav from "@/views/forms/option_form/OptionNav.vue";
import OptionForm from "@/views/forms/option_form/OptionForm.vue";
import OptionInput from "@/views/forms/option_form/OptionInput.vue";
import EventBus from "@/utils/EventBus";

export default {
  name: "VerifyEmail",
  components: {OptionNav, OptionInput, OptionForm},
  props: {
    userData: {type: Object, required: true},
    request: {type: RequestManager, required: true},
  },
  data () {
    return {
      pin: '',
    }
  },
  methods: {
    setPin(value){this.pin = value},
    async verifyClicked() {
      const response = await this.request.verifyRequest(this.userData, "signup", this.pin);
      const msg = response.msg;
      if(msg !== "SUCCESS") return EventBus.emit("set-error", msg);
      EventBus.emit("set-error", "Success! Please login again in order to proceed.");
      EventBus.emit("logout-user");
    },
    async resendClicked() {
      const response = await this.request.resendEmail(this.userData);
      const msg = response.msg;
      if(msg !== "SUCCESS") return EventBus.emit("set-error", msg);
      EventBus.emit("set-error", "Success! Please check your inbox.");
    }
  },
}
</script>
