<template>
  <section class="absolute inset-0 top-16 flex items-center justify-center bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img class="w-8 h-8 mr-2" :src="icons.render" alt="logo">
        Realistic Render
      </a>

      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            {{title}}
          </h1>
          <form class="space-y-4 md:space-y-6" @submit.prevent="$emit('submit')">
            <slot></slot>
            <button
                type="submit"
                class="w-full text-white bg-amber-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {{ submitName }}
            </button>
          </form>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {IconsUI} from "@/utils/IconsUI";

export default {
  name: "UserForm",
  emits: ['submit'],
  props: {
    icons: {type: IconsUI, required: true},
    title: {type: String, required: false},
    submitName: {type: String, required: true},
  }
}
</script>
