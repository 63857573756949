<template>
  <div>
    <nav class="bg-white border-gray-200 dark:bg-gray-800">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <router-link to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img :src="icons.logo" alt="logo" class="h-8" />
        </router-link>
        <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-user">
<!--          <div class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">-->
          <div class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:border-gray-700">
            <a href="https://3d.real-api.com" :class="itemCss" target="_blank">Demo</a>
            <a href="https://docs.realistic3.com" :class="itemCss" target="_blank">API Docs</a>
            <router-link v-if="!loggedIn" to="/about" :class="itemCss">About us</router-link>
<!--            <router-link v-if="!loggedIn" to="/pricing" :class="itemCss">Pricing</router-link>-->
            <router-link v-if="!loggedIn" to="/login" :class="itemCss">My account</router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>


<script>
import {IconsUI} from "@/utils/IconsUI";

export default {
  name: "NavbarMenus",
  props: {
    icons: {type: IconsUI, required: true},
    loggedIn: {type: Boolean, required: true},
  },
  data () {
    return {
      itemCss: "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700",
    }
  },
};
</script>

