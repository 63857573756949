<template>
  <div class="absolute bg-gray-100 dark:bg-gray-800 mt-24 w-full">
    <div class="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl dark:text-white">
        About Us
      </h2>
      <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-0 dark:text-gray-300">
        {{ oneLiner }}
      </p>
      <div class="mt-6 lg:mt-10">
        <p class="text-lg text-gray-500 dark:text-gray-300">{{ intro }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  data() {
    return {
      oneLiner: "USMANHEART OÜ has a legal form of Private limited company, formed in the year 2022 and operates in Computer programming activities sector",
      intro: "Welcome to our company, a leader in cloud-based 3D rendering services. Our platform provides fast and affordable rendering solutions for individuals and businesses of all sizes. Our technology supports a wide range of file formats and provides customizable APIs and SDKs for seamless integration into your workflow.\n\nOur goal is to make 3D rendering accessible to everyone, whether you're a hobbyist or a professional, without sacrificing quality or performance. With our pay-per-use model, you only pay for the rendering time you need, saving you time and money.\n\nWe are passionate about empowering creators with the tools they need to bring their visions to life. Join us today and experience the power of cloud rendering"
    };
  }
}
</script>
