<template>
  <div v-if="isPassword()">
    <UserForm title="Change password" submit-name="Change" :icons="icons" @submit="changePassword">
      <UserInput
          type="email"
          :placeholder="getEmail()"
          :readonly="true"
      />
      <UserInput
          :require="true"
          type="password"
          placeholder="New password"
          @onChange="value => {password=value}"
      />
      <ul v-if="pError && pError.length">
        <li v-for="(error, index) in pError" :key="index" class="text-red-500">
          <span class="bullet">&#8226;</span> {{ error }}
        </li>
      </ul>
      <UserInput
          :require="true"
          type="password"
          placeholder="Confirm password"
          @onChange="value => {password2=value}"
      />
      <p v-if="p2Error" class="text-red-500">{{ p2Error }}</p>
    </UserForm>
  </div>
</template>
<script>
import {IconsUI} from "@/utils/IconsUI";
import EventBus from "@/utils/EventBus";
import {CheckStrength} from "@/tools/hash_tools";
import RequestManager from "@/utils/RequestManager";
import UserForm from "@/views/forms/user_form/UserForm.vue";
import UserInput from "@/views/forms/user_form/UserInput.vue";

export default {
  name: "ChangeInfo",
  components: {UserInput, UserForm},
  props: {
    icons: {type: IconsUI, required: true},
    request: {type: RequestManager, required: true},
  },
  data() {
    return {
      pError: [],
      p2Error: '',
      password: '',
      password2: '',
      query: this.$route.query,
    }
  },
  methods: {
    async changePassword(){
      const password = this.password;
      this.pError = CheckStrength(password);
      if(this.pError && this.pError.length) return;
      if(password !== this.password2) {
        this.p2Error = "Password doesn't match";
        return;
      }
      const data = this.query.data;
      const email = this.getEmail();
      const response = await this.request.resetPassword(email, password, data);
      const msg = response.msg;
      if(msg === "SUCCESS") EventBus.emit("set-info", {msg: "Success!", red: "login"});
      else EventBus.emit("set-info", {msg: msg ? msg : "Something went wrong!", red: "login"});
    },
    getEmail() {
      return this.query && this.query.email;
    },
    isPassword() {
      return this.query && this.query.type === "password";
    }
  }
}
</script>
