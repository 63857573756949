<template>
  <div>
    <UserForm title="Sign in to your account" submit-name="Login" :icons="icons" @submit="login">
      <UserInput
          :require="true"
          placeholder="Username or Email"
          @onChange="value => {data=value}"
      />

      <UserInput
          type="password"
          :require="true"
          placeholder="••••••••"
          @onChange="value => {password=value}"
      />

      <div class="flex items-center justify-between">
        <div class="flex items-start">
          <div class="flex items-center h-5">
            <input id="remember" aria-describedby="remember" type="checkbox" v-model="remember" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800">
          </div>
          <div class="ml-3 text-sm">
            <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
          </div>
        </div>
        <a href="#" @click.prevent="$emit('forgot')" class="text-sm font-medium text-primary-600 hover:underline dark:text-gray-300">Forgot password?</a>
      </div>

      <p class="text-sm font-light text-gray-500 dark:text-gray-400">
        Don’t have an account yet? <a href="#" @click.prevent="$emit('signup')" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
      </p>

    </UserForm>
  </div>
</template>
<script>
import {IconsUI} from "@/utils/IconsUI";
import UserForm from "@/views/forms/user_form/UserForm.vue";
import UserInput from "@/views/forms/user_form/UserInput.vue";
import EventBus from "@/utils/EventBus";
import RequestManager from "@/utils/RequestManager";
import CacheManager from "@/utils/CacheManager";

export default {
  name: "LoginForm",
  components: {UserInput, UserForm},
  emits: ['signup', 'forgot', 'submit'],
  props: {
    icons: {type: IconsUI, required: true},
    cache: {type: CacheManager, required: true},
    request: {type: RequestManager, required: true},
  },
  data() {
    return {
      data: '',
      password: '',
      remember: true,
    };
  },
  methods: {
    async login() {
      console.log('Logging in...');
      try {
        const data = this.data;
        const userData = await this.request.login(data, this.password, true);
        if(!userData) return EventBus.emit("set-error", "Login failed!");
        if(this.remember) {
          const token = userData.token;
          if(token) this.cache.setCache("login", {data: data, token: token});
        }
        // EventBus.emit("user-logged-in", userData);
        // this.$router.push('/');
      }
      catch (error) {
        EventBus.emit("set-error", error);
      }
    },
  }
}
</script>
