
<template>
  <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
    <!-- Username -->
    <div class="mb-4 flex items-center">
      <div class="font-semibold text-gray-900 dark:text-gray-200 w-1/3">Username:</div>
      <div class="text-gray-700 dark:text-gray-300 w-2/3">{{ userData.user.userName }}</div>
    </div>

    <!-- Email -->
    <div class="mb-4 flex items-center">
      <div class="font-semibold text-gray-900 dark:text-gray-200 w-1/3">Email:</div>
      <div class="text-gray-700 dark:text-gray-300 w-2/3">{{ userData.user.email }}</div>
    </div>

    <!-- App Key -->
    <div class="mb-4 flex items-center">
      <div class="font-semibold text-gray-900 dark:text-gray-200 w-1/3">App Key:</div>
      <input type="text" :value="userData.user.appKey" @click="copyToClipboard(userData.user.appKey)" readonly class="w-2/3 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 px-3 py-2 rounded-md cursor-pointer">
    </div>

    <!-- App Secret -->
    <div class="mb-4 flex items-center">
      <div class="font-semibold text-gray-900 dark:text-gray-200 w-1/3">App Secret:</div>
      <input type="text" :value="userData.user.appSecret" @click="copyToClipboard(userData.user.appSecret)" readonly class="w-2/3 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 px-3 py-2 rounded-md cursor-pointer">
    </div>

    <!-- Logout and Modify buttons -->
    <div class="flex justify-center">
<!--      <button @click="modify" class="px-4 mr-2 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md">Modify</button>-->
      <button @click="logout" class="px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded-md">Logout</button>
    </div>

  </div>
</template>


<script>
import {IconsUI} from "@/utils/IconsUI";
import EventBus from "@/utils/EventBus";

export default {
  name: "UserPage",
  props: {
    icons: {type: IconsUI, required: true},
    userData: { type: Object, required: true },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      EventBus.emit("set-toast", "Copied!");
    },
    logout() {
      EventBus.emit("logout-user");
    },
    modify() {
    }
  }
}
</script>
