<template>
  <div>
    <div v-if="menu === 'signup'">
      <SignupForm :icons="icons" @login="loginClicked" @signup="signupClicked" :request="request" />
    </div>
    <div v-else-if="menu === 'forgot'">
      <ForgotForm :icons="icons" @login="loginClicked" @signup="signupClicked" :request="request" />
    </div>
    <div v-else>
      <LoginForm :icons="icons" @forgot="forgotClicked" @signup="signupClicked" :cache="cache" :request="request" />
    </div>
  </div>
</template>

<script>
import {IconsUI} from "@/utils/IconsUI";
import CacheManager from "@/utils/CacheManager";
import RequestManager from "@/utils/RequestManager";
import LoginForm from "@/components/LoginForm.vue";
import SignupForm from "@/components/SignupForm.vue";
import ForgotForm from "@/components/ForgotForm.vue";

export default {
  name: "LoginPage",
  components: {ForgotForm, SignupForm, LoginForm},
  props: {
    icons: {type: IconsUI, required: true},
    cache: {type: CacheManager, required: true},
    request: {type: RequestManager, required: true},
  },
  data() {
    return {
      menu: 'login',
    };
  },
  methods: {
    loginClicked() {
      this.menu = 'login';
    },
    signupClicked() {
      this.menu = 'signup';
    },
    forgotClicked() {
      this.menu = 'forgot';
    },
  }
};
</script>
