import logo from "@/assets/logo.png";
import jsx from "@/assets/img/jsx.svg";
import bbl from "@/assets/img/bbl.svg";
import fbx from "@/assets/img/fbx.svg";
import rest from "@/assets/img/rest.svg";
import gltf from "@/assets/img/gltf.svg";
import dark from "@/assets/img/dark.svg";
import node from "@/assets/img/nodejs.svg";
import three from "@/assets/img/three.svg";
import unity from "@/assets/img/unity.svg";
import blend from "@/assets/img/blend.svg";
import light from "@/assets/img/light.svg";
import python from "@/assets/img/python.svg";
import render from "@/assets/img/render.svg";

export class IconsUI {
    constructor() {
        this.jsx = jsx;
        this.bbl = bbl;
        this.fbx = fbx;
        this.node = node;
        this.gltf = gltf;
        this.logo = logo;
        this.dark = dark;
        this.rest = rest;
        this.three = three;
        this.unity = unity;
        this.blend = blend;
        this.light = light;
        this.python = python;
        this.render = render;
    }
}
