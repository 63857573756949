<template>
  <div v-if="isVisible"
       class="fixed inset-0 flex items-center justify-center z-50 ">
    <div class="dark:bg-white bg-gray-700 dark:text-gray-700 text-gray-300 px-4 py-2 rounded-md">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ToastView",
  emits: ['clearToast'],
  props: {
    message: { type: [String, null], required: true },
    duration: { type: Number, default: 1500 }
  },
  data() {
    return {
      isVisible: !!this.message
    };
  },
  watch: {
    message(newValue) {
      this.isVisible = !!newValue;
      if(this.isVisible) this.runTimer();
    }
  },
  methods: {
    runTimer() {
      setTimeout(() => {
        this.$emit('clearToast');
      }, this.duration);
    }
  }
};
</script>
