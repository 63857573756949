<template>
  <div v-if="isVerified()">
    <div class="absolute right-0 top-16 mt-2 mr-2">
      <UserWallet :user-data="userData" />
    </div>
    <div class="absolute top-16 mt-2 w-[580px] left-1/2 transform -translate-x-1/2">
      <UserPage :request="request" :user-data="userData" :icons="icons"/>
      <ProductPage class="mt-2" :request="request" :user-data="userData" :icons="icons"/>
    </div>

  </div>
  <div v-else>
    <VerifyEmail  :request="request" :user-data="userData"/>
  </div>
</template>
<script>
import {IconsUI} from "@/utils/IconsUI";
import VerifyEmail from "@/pages/VerifyEmail.vue";
import RequestManager from "@/utils/RequestManager";
import UserPage from "@/pages/UserPage.vue";
import UserWallet from "@/views/UserWallet.vue";
import ProductPage from "@/pages/ProductPage.vue";

export default {
  name: "DashboardPage",
  components: {ProductPage, UserWallet, UserPage, VerifyEmail},
  props: {
    icons: {type: IconsUI, required: true},
    userData: {type: Object, required: true},
    request: {type: RequestManager, required: true},
  },
  methods: {
    isVerified() {
      return this.userData && this.userData.account && this.userData.account.verified;
    },

  },
  data () {
    return {
    }
  },
}
</script>
