<template>
  <div>
    <label v-if="label" :for="labelFor" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{label}}</label>
    <input
        :type="type"
        :required="require"
        v-model="onChange"
        :placeholder="placeholder"
        :readonly="readonly"
        step="any"
        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    >
  </div>
</template>
<script>
export default {
  name: "UserInput",
  emits: ['onChange'],
  props: {
    label: {type: [String, undefined], required: false},
    labelFor: {type: [String, undefined], required: false},
    placeholder: {type: [String, undefined], required: false},
    require: {type: Boolean, required: false, default: ()=>{return false}},
    readonly: {type: Boolean, required: false, default: ()=>{return false}},
    type: {type: String, required: false, default: () => {return 'text'}},
    def: {type: [Boolean, Number, String, undefined], required: false, default: ()=>{return undefined}},
  },
  data () {
    return{
      onChange: this.def
    }},
  watch: {
    onChange(value){
      this.$emit('onChange', value);
    },
  }
}
</script>
