import '@/style.css';
import '@stripe/stripe-js'
import App from './App.vue'
import store from './store'
import {createApp, ref} from 'vue'
import {Routes} from './router/routes'
import * as VueRouter from 'vue-router';
import {IconsUI} from "@/utils/IconsUI";
import {LoadConfig} from "@/config/config";
import {loadStripe} from "@stripe/stripe-js"
import CacheManager from "@/utils/CacheManager";
import RequestManager from "@/utils/RequestManager";


const setupApp = async () => {
    let guardRemoved = false;
    const icons = new IconsUI();
    const config = await LoadConfig();
    const cache = new CacheManager();
    const request = new RequestManager(config, cache);
    const loading = ref(false);
    // const chunks = "pk_test_51JM3txHRFj8mj4UGMOTNoDIAUi95cWQN9FHuKP73RPHTotsJaWFrY1B9lUiXsDpftbhESl6bNbSGN2ytjj5WHMdl00mcSMcpem";
    const chunks = await request.getChunks();
    let stripe = undefined;
    if(chunks) stripe = await loadStripe(chunks);

    const routes = Routes(cache, request, stripe);
    const router = VueRouter.createRouter({
        history: VueRouter.createWebHashHistory(),
        routes,
    })

    router.beforeEach(async (to, from, next) => {
        if (guardRemoved) {
            next();
            return;
        }
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        loading.value = true;
        const isAuth = await request.loginFromCache();
        if(isAuth) guardRemoved = true;
        loading.value = false;
        if (requiresAuth && !isAuth) {
            next('/login');
        } else {
            next();
        }
    });

    const app = createApp(App);
    app.use(store);
    app.provide('icons', icons);
    app.provide('cache', cache);
    app.provide('stripe', stripe);
    app.provide('config', config);
    app.provide('loading', loading);
    app.provide('request', request);
    app.use(router);
    app.mount('#app');
    return { app, router };
}
setupApp().then(r => {return r});
