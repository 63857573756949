<template>
  <div v-if="canPay"
       class="fixed top-0 right-0 bottom-0 left-0 flex bg-gray-200 items-center justify-center z-50 backdrop-filter backdrop-blur-md">
    <!-- Display a payment form -->
    <form id="payment-form">
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <button id="submit" @click="handleSubmit">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">Pay now</span>
      </button>
      <div id="payment-message" class="hidden"></div>
    </form>
  </div>
  <div v-else>
    <OptionForm title="Top-up Amount" submit-name="Confirm" :ignore-close="true" @submit="amountSet">
      <OptionInput type="number" label="Amount (EUR)" :def="amount" @onChange="setAmount" />
    </OptionForm>
  </div>
</template>
<script>
import EventBus from "@/utils/EventBus";
import RequestManager from "@/utils/RequestManager";
import OptionForm from "@/views/forms/option_form/OptionForm.vue";
import OptionInput from "@/views/forms/option_form/OptionInput.vue";

export default {
  name: "PaymentPage",
  components: {OptionInput, OptionForm},
  props: {
    userData: {type: Object, required: true},
    request: {type: RequestManager, required: true},
    stripe: {type: [Object, undefined], required: true},
  },
  data () {
    // this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      amount: 1,
      canPay: false,
      elements: null,
      success: `${window.location.origin}/pay_success`,
      paymentMethodTypes: ['card', 'alipay', 'klarna', 'ideal', 'giropay', 'p24', 'paypal'],
    };
  },
  methods: {
    setAmount(value){this.amount = parseInt(value)},
    async amountSet() {
      this.canPay = true;
      this.loadStripeTheme();
      await this.createPayment();
    },
    async createPayment() {
      const email = this.userData.user.email;
      const amount = this.amount;
      const purpose = 'product';
      const currency = 'EUR';
      const body = {
        amount: amount*100,
        email: email,
        purpose: purpose,
        currency: currency,
        paymentMethodTypes: ['card', 'alipay', 'klarna', 'ideal', 'giropay', 'p24', 'paypal'],
        automatic_payment_methods: {enabled: true},
      }
      const info = await this.request.createStripeIntents(body);
      if(info.msg !== "SUCCESS") return EventBus.emit("set-error", info.msg);
      const data = info.data;
      if(!data || !data.intents) return EventBus.emit("set-error", "Checkout plugin loading failed!");
      await this.loadStripeElements(data.intents);
    },
    async loadStripeElements(intents) {
      const stripe = this.stripe;
      // const appearance = {
      //   theme: 'stripe',
      // };

      const appearance = {
        theme: 'flat',
        variables: {
          fontFamily: ' "Gill Sans", sans-serif',
          fontLineHeight: '1.5',
          borderRadius: '10px',
          colorBackground: '#F6F8FA',
          accessibleColorOnColorPrimary: '#262626'
        },
        rules: {
          '.Block': {
            backgroundColor: 'var(--colorBackground)',
            boxShadow: 'none',
            padding: '12px'
          },
          '.Input': {
            padding: '12px'
          },
          '.Input:disabled, .Input--invalid:disabled': {
            color: 'lightgray'
          },
          '.Tab': {
            padding: '10px 12px 8px 12px',
            border: 'none'
          },
          '.Tab:hover': {
            border: 'none',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
          },
          '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
            border: 'none',
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
          },
          '.Label': {
            fontWeight: '500'
          }
        }
      };

      // const appearance = {
      //   theme: 'night',
      //   labels: 'floating'
      // };

      // const appearance = {
      //   theme: 'flat'
      // };

      const clientSecret = intents["client_secret"];
      const elements = stripe.elements({ appearance, clientSecret });
      this.elements = elements;
      const paymentElementOptions = {
        layout: "tabs",
      };
      const paymentElement = elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.setLoading(true);
      const stripe = this.stripe;
      const elements = this.elements;

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/#/pay_success`,
        },
      });
      if (error.type === "card_error" || error.type === "validation_error") {
        this.showMessage(error.message);
      } else {
        this.showMessage("An unexpected error occurred.");
      }

      this.setLoading(false);
      this.unloadStripeTheme();
    },
    loadStripeTheme() {
      if (!document.querySelector('link[href="@/checkout.css"]')) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = './checkout.css';
        link.dataset.id = 'checkout-css';
        document.head.appendChild(link);
      }
    },
    unloadStripeTheme() {
      const existingLink = document.querySelector('link[data-id="checkout-css"]');
      if (existingLink) {
        existingLink.parentNode.removeChild(existingLink);
      }
    },
    showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");

      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageContainer.textContent = "";
      }, 4000);
    },
    setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    }
  },
  async mounted() {
  }
}
</script>
