import bcrypt from "bcryptjs";

export async function HashPassword(password) {
    const salt = await bcrypt.genSalt(12);
    return await bcrypt.hash(password, salt);
}

export async function CheckPassword(hash, pass){
    return await bcrypt.compare(pass, hash);
}


export function CheckStrength(password) {
    let errors = [];
    // Check for length of the password
    if (password.length < 8) {
        errors.push('Password must be at least 8 characters long.');
    }

    // Check for lowercase letters
    if (!/[a-z]/.test(password)) {
        errors.push('Password must contain at least one lowercase letter.');
    }

    // Check for uppercase letters
    if (!/[A-Z]/.test(password)) {
        errors.push('Password must contain at least one uppercase letter.');
    }

    // Check for numbers
    if (!/\d/.test(password)) {
        errors.push('Password must contain at least one number.');
    }

    // Check for special characters
    // if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
    //     return 'Password must contain at least one special character.';
    // }

    return errors;
}
