<template>
  <div>
    <UserForm title="Create a new account" submit-name="Sign up" :icons="icons" @submit="signup">

      <UserInput
          :require="true"
          type="email"
          placeholder="Email"
          @onChange="value => {email=value}"
      />

      <UserInput
          :require="true"
          placeholder="Username"
          @onChange="value => {username=value}"
      />

      <UserInput
          :require="true"
          type="password"
          placeholder="Password"
          @onChange="value => {password=value; checkStrength(value);}"
      />
      <ul v-if="pError && pError.length">
        <li v-for="(error, index) in pError" :key="index" class="text-red-500">
          <span class="bullet">&#8226;</span> {{ error }}
        </li>
      </ul>
      <UserInput
          :require="true"
          type="password"
          placeholder="Confirm password"
          @onChange="value => {password2=value}"
      />
      <p v-if="p2Error" class="text-red-500">{{ p2Error }}</p>

    </UserForm>
  </div>
</template>
<script>
import {IconsUI} from "@/utils/IconsUI";
import EventBus from "@/utils/EventBus";
import RequestManager from "@/utils/RequestManager";
import UserForm from "@/views/forms/user_form/UserForm.vue";
import UserInput from "@/views/forms/user_form/UserInput.vue";
import {CheckStrength} from "@/tools/hash_tools";

export default {
  name: "SignupForm",
  components: {UserInput, UserForm},
  emits: ['login', 'submit'],
  props: {
    icons: {type: IconsUI, required: true},
    request: {type: RequestManager, required: true},
  },
  data() {
    return {
      email: '',
      pError: [],
      p2Error: '',
      username: '',
      password: '',
      password2: '',
    };
  },
  methods: {
    async signup() {
      const password = this.password;
      this.pError = CheckStrength(password);
      if(this.pError && this.pError.length) return;
      if(password !== this.password2) {
        this.p2Error = "Password doesn't match";
        return;
      }
      const response = await this.request.signup(this.email, this.username, this.password);
      const msg = response.msg;
      switch (msg) {
        case "SUCCESS":
          EventBus.emit("set-info", {msg: "Signup completed!", red: "login"});
          break;
        case "ALREADY":
          EventBus.emit("set-info", {msg: "User already exists!", red: "login"});
          break;
        default:
          EventBus.emit("set-info", {msg: msg ? msg : "Something went wrong!", red: "login"});
          break;
      }
    }
  }
}
</script>
