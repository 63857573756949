<template>
  <a class="text-sm font-medium text-primary-600 dark:text-gray-300">
    {{title}}
    <a href="#" @click.prevent="$emit('submit')" class="text-sm font-medium text-blue-400 hover:underline dark:text-orange-400">{{ submitName }}</a>
  </a>
</template>

<script>
export default {
  name: "OptionNav",
  emits: ['submit'],
  props: {
    title: {type: String, required: false},
    submitName: {type: String, required: true},
  }
}
</script>
