<template>
  <div class="mb-4">
    <input
        :type="type"
        :required="require"
        v-model="onChange"
        :placeholder="label"
        step="any"
        class="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
    >
  </div>
</template>
<script>
export default {
  name: "OptionInput",
  emits: ['onChange'],
  props: {
    label: {type: String, required: true},
    require: {type: Boolean, required: false, default: ()=>{return false}},
    type: {type: String, required: false, default: () => {return 'text'}},
    def: {type: [Boolean, Number, String], required: false, default: ()=>{return false}},
  },
  data () {
    return{
      onChange: this.def
    }},
  watch: {
    onChange(value){
      this.$emit('onChange', value);
    },
  }
}
</script>
