<template>
  <div>
    <UserForm title="Reset password" submit-name="Reset" :icons="icons" @submit="forget">

      <UserInput
          :require="true"
          type="email"
          placeholder="Email"
          @onChange="value => {email=value}"
      />

    </UserForm>
  </div>
</template>
<script>
import {IconsUI} from "@/utils/IconsUI";
import RequestManager from "@/utils/RequestManager";
import UserForm from "@/views/forms/user_form/UserForm.vue";
import UserInput from "@/views/forms/user_form/UserInput.vue";
import EventBus from "@/utils/EventBus";

export default {
  name: "ForgotForm",
  components: {UserInput, UserForm},
  props: {
    icons: {type: IconsUI, required: true},
    request: {type: RequestManager, required: true},
  },
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async forget() {
      const email = this.email;
      await this.request.forget(email);
      EventBus.emit("set-info", {msg: "If your information matches our record, please check you inbox!", red: "login"});
    }
  }
}
</script>
