<template>
  <div id="app">
    <ToastView :message="toast" :duration="toastDuration" @clearToast="clearToast" />
    <InfoView v-if="info" :info="info" @clearInfo="clearInfo" />
    <ErrorView v-if="error" :errorMessage="error" @clearError="clearError" />
    <ThemeToggle :icons="icons" />
    <LoadingView :loading="loading" />
    <router-view
        :icons="icons"
        :loading="loading"
        :request="request"
        :userData="userData"
        :loggedIn="loggedIn"
        :chunkFailed="chunkFailed"
    />
    <NavbarMenus v-if="!loading" :icons="icons" :loggedIn="loggedIn" />
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import EventBus from "@/utils/EventBus";
import ErrorView from "@/views/ErrorView.vue";
import ToastView from "@/views/ToastView.vue";
import LoadingView from "@/views/LoadingView.vue";
import ThemeToggle from "@/components/ThemeToggle.vue";
import NavbarMenus from "@/components/NavbarMenus.vue";
import InfoView from "@/views/InfoView.vue";


export default {
  name: 'App',
  inject: ['config', 'stripe', 'cache', 'request', 'loading', 'icons'],
  components: {InfoView, ToastView, NavbarMenus, ErrorView, ThemeToggle, LoadingView},
  data() {
    return {
      userData: {},
      info: null,
      error: null,
      toast: null,
      loggedIn: false,
      chunkFailed: false,
      toastDuration: 1500,
      menuError: undefined,
    }
  },
  methods: {
    async onLoggedIn(userData) {
      if(!userData || !userData.user) return;
      this.userData = userData;
      this.loggedIn = true;
      this.$router.push('/');
    },
    logoutUser(){
      // console.log("logoutUser")
      this.cache.remove('login');
      this.userData = {};
      this.$router.push('/login');
    },
    isRootRoute() {
      const router = useRouter();
      return router.currentRoute.value.path === '/';
    },
    setInfo(msg) {
      this.info = msg;
    },
    setError(msg) {
      this.error = msg;
    },
    setToast(msg) {
      this.toast = msg;
      this.toastDuration = 1500;
    },
    clearInfo() {
      this.info = null;
    },
    clearError() {
      this.error = null;
    },
    clearToast() {
      this.toast = null;
    }
  },
  async mounted() {
    if(!this.stripe) {
      this.chunkFailed = true;
      this.toastDuration = 5500;
      this.toast = "Network Error! Please check your connection";
      // return;
    }
    // this.setInfo({msg: "Website is under maintenance, you might face some functions are unavailable. We are sorry for inconvenience"})
    // this.setInfo({msg: "Our service is under maintenance, you might face some functions are unavailable. We are sorry for inconvenience"})
  },
  beforeMount() {
    EventBus.on('set-info', this.setInfo);
    EventBus.on('set-error', this.setError);
    EventBus.on('set-toast', this.setToast);
    EventBus.on('logout-user', this.logoutUser);
    EventBus.on('user-logged-in', this.onLoggedIn);
  },
  beforeUnmount() {
    EventBus.off('set-info', this.setInfo);
    EventBus.off('set-error', this.setError);
    EventBus.off('set-toast', this.setError);
    EventBus.off('logout-user', this.logoutUser);
    EventBus.off('user-logged-in', this.onLoggedIn);
  },
}
</script>
<style>
body {
  @apply bg-gray-200 dark:bg-gray-900;
}
</style>
<!--@apply bg-gray-100 dark:bg-sc-dark;-->
